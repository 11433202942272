import GTMScript from "../components/GTM/GTMScript";
import "../styles/globals.css";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout ?? ((page) => page)
  return getLayout(
    <>
      <GTMScript />
      <Script id="cookieState">
        {`
          function getCookie(name) {
            const value = "; " + document.cookie;
            const parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(';').shift();
          }
          window.loginState = {};
          try {
            let tmp = decodeURIComponent(getCookie('user-data'));
            if (tmp && tmp != 'undefined') {
              window.loginState._userData = JSON.parse(tmp);
            }
          } catch (err) {
            console.log(err);
          }
        `}
      </Script>

      <Script id="ay-script" data-no-optimize="1" data-cfasync="false" src="https://E6p44qs4xbSvnv8BG.ay.delivery/manager/E6p44qs4xbSvnv8BG" referrerpolicy="no-referrer-when-downgrade"></Script>
     
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
